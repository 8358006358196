const IconLinkedin = () => (
    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.5992 8.2002C18.2303 8.2002 19.7946 8.84814 20.9479 10.0015C22.1013 11.1548 22.7492 12.7191 22.7492 14.3502V21.5252H18.6492V14.3502C18.6492 13.8065 18.4332 13.2851 18.0488 12.9006C17.6643 12.5162 17.1429 12.3002 16.5992 12.3002C16.0555 12.3002 15.5341 12.5162 15.1497 12.9006C14.7652 13.2851 14.5492 13.8065 14.5492 14.3502V21.5252H10.4492V14.3502C10.4492 12.7191 11.0972 11.1548 12.2505 10.0015C13.4039 8.84814 14.9681 8.2002 16.5992 8.2002V8.2002Z"
            stroke="currentColor"
            strokeWidth="1.5375"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.35 9.2251H2.25V21.5251H6.35V9.2251Z"
            stroke="currentColor"
            strokeWidth="1.5375"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M4.3 6.15029C5.43218 6.15029 6.35 5.23248 6.35 4.10029C6.35 2.96811 5.43218 2.05029 4.3 2.05029C3.16782 2.05029 2.25 2.96811 2.25 4.10029C2.25 5.23248 3.16782 6.15029 4.3 6.15029Z"
            stroke="currentColor"
            strokeWidth="1.5375"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default IconLinkedin;
